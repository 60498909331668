import React, { useState, useEffect } from 'react'
import '../styles/login.scss'
import { Helmet } from 'react-helmet'
import TextField from '@material-ui/core/TextField'
import useWindowDimesions from '../components/useWindowDimesions'
import CheckAuth from '../components/CheckAuth'
import { useSelector } from 'react-redux'
import { callApi } from '../utils/api-utils'
// import { register } from '../actions/userActions'

import authConfig from '../config/auth'
import ReactGA from 'react-ga4'
import SplashScreen from './SplashScreen'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { v4 as uuidv4 } from 'uuid'

// const referralCode = length => {
// let result = ''
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//   const charactersLength = characters.length
//   let counter = 0
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength))
//     counter += 1
//   }
//   return result
// }

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const LogInEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/login/user-login'
}
const verifyTokenEndpoint = {
  ...defaults.methods.POST,
  uri: '/api/referralHistory/add-referral-history'
}

export default function Login(props) {
  const { width } = useWindowDimesions()

  const userRegister = useSelector(state => state.userRegister)
  const { userInfo } = userRegister

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wait, setWait] = useState(false)

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (userInfo) {
      window.location.href('/')
    }

    ReactGA.event({
      category: 'Login-Page',
      action: 'Login-Page',
      label: 'Login-Page' // optional
    })
  }, [props.history, userInfo])

  async function LoginSubmit(e) {
    e.preventDefault()
    setWait(true)
    const body = { email: email, password: password, device_id: uuidv4() }

    await callApi({ uriEndPoint: LogInEndpoint, body })
      .then(async res => {
        if (res?.data) {
          await localStorage.setItem(authConfig.storageTokenKeyName, res?.data?.token || '')

          await localStorage.setItem('userData', JSON.stringify(res.data))
          await callApi({ uriEndPoint: verifyTokenEndpoint })
            .then(async () => {
              localStorage.setItem('mongoId', res.data.id)

              localStorage.setItem('referral_code', res.data.referral_code)
              // const stringifyDataBuyCourse = JSON.stringify(res.data.course_purchase)
              // await localStorage.setItem('buyCourses', stringifyDataBuyCourse)
              const stringifyDataStudent = JSON.stringify(res.data.personData)
              await localStorage.setItem('profileInformation', stringifyDataStudent)
              await localStorage.setItem('studentStore', stringifyDataStudent)
              window.location.href = '/'
            })
            .catch(() => {
              setTimeout(() => {
                setWait(false)
              }, 2000)
            })
        }
        if (res.statusCode === 202) {
          alert(res.message)
          setTimeout(() => {
            setWait(false)
          }, 2000)
        }
      })
      .catch(e => {
        setWait(false)
        if (e?.statusCode === 406) {
          alert(e.message || 'Account Temporary Deactivated')
        }
      })
  }

  if (wait) {
    return <SplashScreen />
  } else {
    return (
      <CheckAuth
        imp={
          <>
            <div className='login' style={{ backgroundColor: '#252d4a' }}>
              <Helmet>
                <title> Login | Samuel Hahnemann Academy for Homeopathy – India's Only Homeopathy E-Learning</title>
              </Helmet>
              <div className='signup-form' style={{ paddingBottom: width < 999 ? 30 : 6, paddingTop: 45 }}>
                <img
                  className='logo'
                  src='/shahlogo.svg'
                  alt='drkrutikshah.online'
                  style={{ cursor: 'pointer' }}
                  onClick={() => (window.location.href = '/')}
                />

                <form onSubmit={LoginSubmit}>
                  <TextField
                    required
                    id='outlined-required'
                    className='email'
                    label='Email address '
                    //   defaultValue="Hello World"
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    size='small'
                    variant='outlined'
                  />
                  <TextField
                    required
                    id='outlined-required'
                    className='password'
                    label='Password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    size='small'
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleTogglePasswordVisibility} edge='end'>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <button style={{ cursor: 'pointer' }} type='submit'>
                    LogIn
                  </button>
                </form>

                <div className='extra-option'>
                  <div className='options'>
                    Haven't any account{' '}
                    <a
                      href='/signup'
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        fontWeight: 'bold'
                      }}
                    >
                      SignUp?
                    </a>
                  </div>
                  <div className='options'>
                    <a href='/forget-password' style={{ textDecoration: 'none', color: '#000' }}>
                      Forgot Password
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    )
  }
}
