import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Forget from '../../Pages/Forget'
import Login from '../../Pages/Login'
import Contact from '../../Pages/Contact'
import Home from '../../Pages/Home'
import Error from '../../Pages/Error'
import AboutShah from '../../Pages/AboutShah'
import Team from '../../Pages/TeamShah'

import Scholar from '../../Pages/Scholar'
import Faqs from '../../Pages/Faqs'
import BrowseCourse from '../../Pages/BrowseCourse'
import WhyStudy from '../../Pages/WhyStudy'
import Quizplay from '../../Pages/Quizplay'
import PressRelease from '../../Pages/PressRelease'
import Carrers from '../../Pages/Carrers'
import CoursePage from '../../Pages/CoursePage'
import DigitalOrganon from '../../Pages/DigitalOrganon'
import OrganonAccess from '../../Pages/OrganonAccess'
import Profile from '../../Pages/Profile'
import AccountDelete from '../../Pages/AccountDelete'
import ChatWithShah from '../../Pages/ChatWithShah'
import Payment from '../../Pages/Payment'
import LiveShah from '../../Pages/LiveShah'
import Terms from '../../Pages/Terms'
import CourseViewer from '../../Pages/CourseViewer'
// import CourseViewer from '../../Pages/testCouresView'
import PurchasedCourse from '../../Pages/PurchasedCourse'
import KBH from '../../Pages/KBH'

import DisclaimerShah from '../../Pages/DisclaimerShah'
import Signup from '../../Pages/Signup'

import InauguralPage from '../../Pages/InauguralPage'
import ShopingCart from '../../components/shopingcard'
import CourseDetails from '../../Pages/CourseDetailPage'

import TestCoursePage from '../../Pages/TestCoursePage'

import FreeTalksPage from '../../Pages/FreeTalksPage'

import AboutInstructor from '../../Pages/aboutInstructorPage'
import ShahLearnings from '../../Pages/ShahLearnings'
import DigitalOrganonApp from '../../Pages/ShahLearnings/DigitalOrganon'
import OrganonApp from '../../Pages/ShahLearnings/Organon'
import KBHApp from '../../Pages/ShahLearnings/KBH'
import AppCoursePage from '../../Pages/ShahLearnings/AppCoursePage'
import Certificate from '../../Pages/Certificate'
import AttendFreeWebinar from '../../Pages/attend-free-webinar'
import QuestionAndAnswer from '../../Pages/question-answer'
import PostInauguralFelicitations from '../../Pages/Post-Inaugural-Felicitations'
import AttendPaidWebinar from '../../Pages/attend-paid-webinar'
import Logger from '../../logger'
import Chat from '../../Pages/Chat'
import AllChapterMainPage from '../../Pages/all-chapter'
import ViewChapterPage from '../../Pages/chapter-view'

// import { Payment } from '@material-ui/icons';

//test@drkrutikshah.online
//343657#WE

export default function Public() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<WaitingPage/>} /> */}
        <Route path='/' element={<Home />} />
        <Route path='/:slug' element={<TestCoursePage />} />
        <Route path='/signup' element={<Signup />} />
        {/* 
          <Route
            path="/practitioner-signup"
            element={<PractitionerSignup/>}
        
          /> */}
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/shah' element={<AboutShah />} />

        <Route path='/scholarships' element={<Scholar />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path='/carrers' element={<Carrers />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/account-delete' element={<AccountDelete />} />
        <Route path='/chat' element={<Chat />} />
        <Route path='/purchased-course' element={<PurchasedCourse />} />
        <Route path='/terms-conditions' element={<Terms />} />
        {/* <Route path='/view-course/:slug' element={<CourseViewer />} /> */}
        <Route path='/view-course/:slug' element={<CourseViewer />} />
        <Route path='/chat-with-shah' element={<ChatWithShah />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='/live-shah' element={<LiveShah />} />

        <Route path='/test-course/:slug' element={<CoursePage />} />

        <Route path='/press-release' element={<PressRelease />} />
        <Route path='/why-study-at-shah' element={<WhyStudy />} />
        <Route path='/team' element={<Team />} />
        <Route path='/beahomeopath' element={<KBH />} />
        <Route path='/browse-course' element={<BrowseCourse />} />
        <Route path='/quizplay' element={<Quizplay />} />
        <Route path='/disclaimer' element={<DisclaimerShah />} />
        <Route path='/ceremony' element={<InauguralPage />} />
        <Route path='/testing' element={<ShopingCart />} />
        <Route path='/courseDetails' element={<CourseDetails />} />

        <Route path='/FreeTalks' element={<FreeTalksPage />} />

        <Route path='/Free-Talks' element={<FreeTalksPage />} />
        <Route path='/certificate/:id' element={<Certificate />} />
        <Route path='/drkrutikshah' element={<AboutInstructor />} />

        <Route path='/felicitations' element={<PostInauguralFelicitations />} />
        <Route path='/digitalorganon' element={<DigitalOrganon />} />
        <Route path='/accesstosuccess' element={<OrganonAccess />} />
        <Route path='/forget-password' element={<Forget />} />
        <Route path='/shahlearnings/m' element={<ShahLearnings />} />
        <Route path='/shahlearnings/m/digital' element={<DigitalOrganonApp />} />
        <Route path='/shahlearnings/m/organon' element={<OrganonApp />} />
        <Route path='/shahlearnings/m/kbh' element={<KBHApp />} />
        <Route path='/shahlearnings/m/course/:slug' element={<AppCoursePage />} />
        <Route path='/paidwebinar' element={<AttendPaidWebinar />} />
        <Route path='/freewebinar' element={<AttendFreeWebinar />} />
        <Route path='/qawithshah' element={<QuestionAndAnswer />} />
        <Route path='/all-chapter' element={<AllChapterMainPage />} />
        <Route path='/all-chapter/:id' element={<ViewChapterPage />} />

        <Route path='/logger' element={<Logger />} />

        <Route element={<Error />} />
      </Routes>
    </Router>
  )
}
